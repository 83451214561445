<template>
    <div class="section">
        <page-title :title="title" :subtitle="subtitle"></page-title>
        <b-row>
            <b-col cols="12" lg="12">
                <b-card :title="$t('client.pages.dashboard.cards.nbInterventions.title')" title-tag="h5" class="mb-3">
                    <spinner v-if="refreshCountData"></spinner>
                    <line-chart
                        :data="monthlyCountData.map(a => a.count)"
                        :labels="
                            monthlyCountData.map(
                                a => this.$i18n.t('shared.general.months.shortForm.' + String(a.month).padStart(2, '0')) + ' - ' + a.year
                            )
                        "
                        v-if="!refreshCountData"
                    ></line-chart>
                </b-card>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12" lg="6">
                <b-card v-if="refreshTopSites">
                    <spinner></spinner>
                </b-card>
                <b-card
                    :title="$t('client.pages.dashboard.cards.siteTable.title')"
                    title-tag="h5"
                    class="mb-3"
                    v-if="!refreshTopSites && topSitesData.length > 0"
                >
                    <client-top-sites-table :site-data="topSitesData"></client-top-sites-table>
                </b-card>
            </b-col>
            <b-col cols="12" lg="6">
                <b-card v-if="refreshCardData">
                    <spinner></spinner>
                </b-card>
                <b-card class="mb-3" v-if="cardData && cardData.count > 0 && !refreshCardData">
                    <b-button :to="{ name: 'ClientPendingQuote' }" type="button" class="btn btn-danger btn-block">
                        {{ $t('client.pages.dashboard.cards.countCard.title') }}: {{ cardData.count }}
                    </b-button>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import PageTitle from '@/layouts/components/PageTitle.vue';
import LineChart from '@/components/shared/chart/LineChart';
import ClientTopSitesTable from '@/components/client/dashboard/ClientTopSitesTable';
import { EVENTS } from '@/constants/client/events';
import { DashboardRepository, QuotationRepository } from '@/repositories';
import InputFilterEvents from '@/views/client/InputFilterEvents';
import Spinner from '@/components/shared/element/Spinner';

export default {
    name: 'ClientHome',
    extends: InputFilterEvents,
    components: {
        LineChart,
        PageTitle,
        ClientTopSitesTable,
        Spinner
    },
    data() {
        return {
            title: this.$t('client.pages.dashboard.title'),
            subtitle: '',
            monthlyCountData: null,
            refreshCountData: true,
            topSitesData: [],
            refreshTopSites: true,
            refreshCardData: true,
            cardData: null
        };
    },
    created() {
        this.getMonthlyData();
        this.getTopSitesData();
        this.getCardCountData();
        this.$on(EVENTS.DASHBOARD.INPUT_CLIENTS_FILTER_CHANGE, () => {
            this.getMonthlyData();
            this.getTopSitesData();
            this.getCardCountData();
        });
    },
    methods: {
        getMonthlyData() {
            this.refreshCountData = true;
            DashboardRepository.getMonthlyCount(this.getClientsAndSiteFilter())
                .then(response => {
                    this.monthlyCountData = response.data.data;
                    this.refreshCountData = false;
                })
                .catch(error => {
                    console.log(error);
                    this.refreshCountData = false;
                });
        },
        getTopSitesData() {
            this.refreshTopSites = true;
            DashboardRepository.getTopSites(this.getClientsAndSiteFilter())
                .then(response => {
                    this.topSitesData = response.data.data;
                    this.refreshTopSites = false;
                })
                .catch(error => {
                    console.log(error);
                    this.refreshTopSites = false;
                });
        },
        getCardCountData() {
            this.refreshCardData = true;
            QuotationRepository.getQuotationsPendingCount(this.getFilters())
                .then(response => {
                    this.refreshCardData = false;
                    this.cardData = response.data.data;
                })
                .catch(error => {
                    this.refreshCardData = false;
                    console.log(error);
                });
        }
    }
};
</script>
